import { Turbo } from '@hotwired/turbo-rails'
import '@/v2/controllers'
import 'trix'
import '@rails/actiontext'

import Alpine from 'alpinejs'
import collapse from '@alpinejs/collapse'
import anchor from '@alpinejs/anchor'
import persist from '@alpinejs/persist'
import uploader from '@/v2/alpine/uploader'

import type { TurboMorphEvent, TurboMorphAttributeEvent } from '@/v2/types'

Alpine.plugin(collapse)
Alpine.plugin(anchor)
Alpine.plugin(persist)
Alpine.data('uploader', uploader)

Alpine.start()

// Copy Alpine state to the new nodes during a morph
addEventListener('turbo:before-morph-element', ((e: TurboMorphEvent) => {
  const { target: oldElement, detail: { newElement } } = e

  if (oldElement.tagName.startsWith('TRIX-')) {
    e.preventDefault()
  }

  if (oldElement?._x_dataStack !== undefined && newElement !== undefined) {
    Alpine.clone(oldElement, newElement)
  }
}) as EventListener)

addEventListener('turbo:before-morph-attribute', ((e: TurboMorphAttributeEvent) => {
  const { target, detail: { attributeName, mutationType } } = e

  // When a popover element is morphed into a new element, close the popover so that it is removed from the top-layer
  if (target.hasAttribute('popover') && attributeName === 'id' && mutationType === 'update') {
    target.hidePopover()
  }

  // Dialog elements do not receive close() when their open attribute is removed, so they must be
  // explicitly removed from the top-layer when this happens via morph.
  // https://github.com/hotwired/turbo/issues/1239
  if (target instanceof HTMLDialogElement && attributeName === 'open' && mutationType === 'remove') {
    e.preventDefault()
    target.close()
  }
}) as EventListener)

// Handle custom redirect event to bust out of turboframes
Turbo.StreamActions.redirect = function () {
  document.querySelector('dialog[open]')?.close()
  Turbo.visit(this.target)
}
